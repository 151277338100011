import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/HomeView'),
        meta: { requiresAuth: true }
      },
      {
        path: '/about',
        name: 'AboutPage',
        component: () => import('../views/AboutView'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '',
    // name: 'BlankLayout',
    component: () => import('../layouts/LoginLayout'),
    children: [
      {
        path: '/login',
        name: 'LoginPage',
        component: () => import('../views/auth/Login'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/pages/Dashboard'),
        meta: { requiresAuth: true }
      }
    ]
  },

  // POS
  {
    path: '',
    component: () => import('../layouts/BlankLayout'),
    children: [
      {
        path: '/pos',
        name: 'POS',
        component: () => import('../views/POS'),
        meta: { requiresAuth: false }
      }
    ]
  },

    // BusinessCard
    {
      path: '',
      component: () => import('../layouts/BlankLayout'),
      children: [
        {
          path: '/business-card/:id?',
          name: 'BusinessCard',
          component: () => import('../views/BusinessCard'),
          meta: { requiresAuth: false }
        }
      ]
    },

  // Product Management
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/products/lists',
        name: 'Lists',
        component: () => import('../views/pages/products/Lists'),
        meta: { requiresAuth: true }
      },
      {
        path: '/products/categories',
        name: 'Categories',
        component: () => import('../views/pages/products/Categories'),
        meta: { requiresAuth: true }
      },
    ]
  },

  // User Management
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/user-management/permissions',
        name: 'Permissions',
        component: () => import('../views/pages/user-management/Permissions'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user-management/roles',
        name: 'Roles',
        component: () => import('../views/pages/user-management/Roles'),
        meta: { requiresAuth: true }
      },
      {
        path: '/user-management/users',
        name: 'Users',
        gate: 'user_access',
        component: () => import('../views/pages/user-management/Users'),
        meta: { requiresAuth: true }
      }
    ]
  },

  // Data Maintenance
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/data-maintenance/barangays',
        name: 'Barangays',
        component: () => import('../views/pages/data-maintenance/Barangays'),
        meta: { requiresAuth: true }
      },
      {
        path: '/data-maintenance/clusters',
        name: 'Clusters',
        component: () => import('../views/pages/data-maintenance/Clusters'),
        meta: { requiresAuth: true }
      },
      {
        path: '/data-maintenance/positions',
        name: 'Positions',
        component: () => import('../views/pages/data-maintenance/Positions'),
        meta: { requiresAuth: true }
      },
      {
        path: '/data-maintenance/candidates',
        name: 'Candidates',
        component: () => import('../views/pages/data-maintenance/Candidates'),
        meta: { requiresAuth: true }
      },
    ]
  },

  // Votes
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/votes',
        name: 'Votes',
        component: () => import('../views/pages/vcs/Votes'),
        meta: { requiresAuth: true }
      },
    ]
  },
  
  //QR Code Generator
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/qr/codegenerator',
        name: 'QR',
        component: () => import('../views/pages/qr/CodeGenerator'),
        meta: { requiresAuth: true }
      }
    ]
  },

  // Time & Attendance
  {
    path: '',
    // name: 'DefaultLayout',
    component: () => import('../layouts/DefaultLayout'),
    children: [
      {
        path: '/timesheet',
        name: 'Timesheet',
        component: () => import('../views/pages/Timesheet'),
        meta: { requiresAuth: true }
      }
    ]
  }, 
  {
    path: '',
    component: () => import('../layouts/BlankLayout'),
    children: [
      {
        path: '/*/',
        name: '404PageNotFound',
        component: () => import('../components/404PageNotFound.vue')
      }
    ]
  }


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);

  if (requiresAuth && !isAuthenticated()) { 
    next('/login');
  } else { 
    next();
  }
});

function isAuthenticated() { 
  const bearerToken = localStorage.getItem('bearerToken');
  return !!bearerToken;
}

export default router