<template>
  <div v-if="loading" class="loading-overlay">
    <div class="loading-component">
      <div class="text-center">
        <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions" :height="75" :width="75" />
        <h5 class="mt-2">Loading Data...</h5>
        <p class="text-muted mb-0">Please wait.</p>
      </div>
    </div>
  </div>
</template>
  
<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/msoeawqm.json";

export default {
  name: "LoadingOverlay",
  components: {
    Lottie,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      defaultOptions: {
        animationData,
      },
    };
  },
};
</script>
  
<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
} 

/* .loading-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
} */

/* .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
} */
</style>