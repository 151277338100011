// import { AbilityBuilder, Ability } from '@casl/ability';

// export function defineAbilities(titles) {
//   const { can, rules } = new AbilityBuilder();

//   if (titles) {
//     titles.forEach(title => {
//       const [action, subject] = title.split('_');
//       can(action, subject);
//     });
//   }

//   return new Ability(rules);
// }

// export default defineAbilities;

import { AbilityBuilder, Ability } from '@casl/ability';

export function defineAbilities(titles) {
  const { can, rules } = new AbilityBuilder();

  if (titles) {
    titles.forEach(title => {
      can(title, 'all');
    });
  }

  return new Ability(rules);
}

export default defineAbilities;