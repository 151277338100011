<template>
    <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
            <b>Version</b> 1.0.0
        </div>
        <!-- <strong>Copyright &copy; 2023 <a href="#">HRIS</a>.</strong> All rights reserved. -->
        <strong><a href="#">KUS - VCARD</a> {{ new Date().getFullYear() }}</strong>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter',
    data() {
        return {

        }
    }
}
</script>

<style lang="scss"></style>