<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/about" class="nav-link">About</router-link>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <!--
            <li class="nav-item">
                <a class="nav-link" data-widget="navbar-search" href="#" role="button">
                    <i class="fas fa-search"></i>
                </a>
                <div class="navbar-search-block">
                    <form class="form-inline">
                        <div class="input-group input-group-sm">
                            <input class="form-control form-control-navbar" type="search" placeholder="Search"
                                aria-label="Search">
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit">
                                    <i class="fas fa-search"></i>
                                </button>
                                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </li>

            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-comments"></i>
                    <span class="badge badge-danger navbar-badge">3</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" class="dropdown-item">

                        <div class="media">
                            <img src="@/assets/logo.png" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    Brad Diesel
                                    <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">Call me whenever you can...</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>

                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">

                        <div class="media">
                            <img src="@/assets/logo.png" alt="User Avatar" class="img-size-50 img-circle mr-3">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    John Pierce
                                    <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">I got your message bro</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>

                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">

                        <div class="media">
                            <img src="@/assets/logo.png" alt="User Avatar" class="img-size-50 img-circle mr-3">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    Nora Silvester
                                    <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">The subject goes here</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>

                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
                </div>
            </li>
 
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                    <i class="fas fa-th-large"></i>
                </a>
            </li>
        -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-danger navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer"
            >See All Notifications</a
          >
        </div>
      </li>

      <li class="nav-item dropdown user-menu">
        <a
          href="#"
          class="nav-link dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="@/assets/profiles/profile-02.png"
            class="user-image img-circle elevation-2"
            alt="User Image"
          />
          <span class="d-none d-md-inline">{{ username }}</span>
        </a>
        <ul
          class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
          style="left: inherit; right: 0px"
        >
          <!-- <li class="user-header bg-primary">
                        <img src="@/assets/profiles/profile-02.png" class="img-circle elevation-2" alt="User Image">
                        <p>
                            Alexander Pierce - Web Developer
                            <small>Member since Nov. 2012</small>
                        </p>
                    </li> -->

          <!-- <li class="user-body">
                        <div class="row">
                            <div class="col-4 text-center">
                                <a href="#">Followers</a>
                            </div>
                            <div class="col-4 text-center">
                                <a href="#">Sales</a>
                            </div>
                            <div class="col-4 text-center">
                                <a href="#">Friends</a>
                            </div>
                        </div>
                    </li> -->

          <!-- <li class="user-footer">
                        <a href="#" class="btn btn-default btn-flat">Profile</a>
                        <a href="#" class="btn btn-default btn-flat float-right">Sign out</a>
                    </li> -->

          <span class="dropdown-item dropdown-header">Administrator</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-user mr-2"></i> Profile
            <span class="float-right text-muted text-sm">&nbsp;</span>
          </a>

          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-lock mr-2"></i> Change Password
            <span class="float-right text-muted text-sm">&nbsp;</span>
          </a>

          <div class="dropdown-divider"></div>
          <!-- <router-link to="/logout" class="dropdown-item">
              <i class="fas fa-sign-out-alt mr-2"></i> Sign Out
              <span class="float-right text-muted text-sm">&nbsp;</span>
          </router-link> -->

          <button class="dropdown-item" @click="logout">
            <i class="fas fa-sign-out-alt mr-2"></i> Sign Out
            <span class="float-right text-muted text-sm">&nbsp;</span>
          </button>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: "AppNavBar",
  data() {
    return {
      username: localStorage.getItem('username')
    };
  },
  
  methods: { 
    async logout() {

      const tokenExpirationTime = localStorage.getItem('tokenExpirationTime');
      const currentTime = Date.now();
 
      if (tokenExpirationTime && currentTime < tokenExpirationTime) {
        try { 
          const bearerToken = localStorage.getItem('bearerToken');

          const headers = {
            Authorization: `Bearer ${bearerToken}`
          };

          // Cookies.remove('jwt_token', { secure: true, sameSite: 'strict' });

          await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/auth/logout`, {}, { headers });

          // this.$toast.success("Logout Successful!");
 
          this.removeLocalStorage()

        } catch (error) {
          this.$toast.error("Oops! Something went wrong!");
        }
      } else {
        // Token is expired, redirect to login page without making the logout API request
        this.removeLocalStorage()
      }
    },

    removeLocalStorage() {
      localStorage.removeItem('userID');
      localStorage.removeItem('roleID');
      localStorage.removeItem('username');
      localStorage.removeItem('bearerToken');
      localStorage.removeItem('tokenExpirationTime');

      // Cookies.remove('jwt_token', { secure: true, sameSite: 'strict' });

      window.history.replaceState(null, '', '/login');

      this.$router.push('/login');

      this.$toast.success("Logout Successful!");
    },
  },
};
</script>

<style lang="scss">
</style>