import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { startSessionTimer } from './utils/session';
// import axios from 'axios';

// axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

Vue.config.productionTip = false

startSessionTimer(30);

import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css'
import 'admin-lte/dist/css/adminlte.min.css?v=3.2.0'
import './style.scss'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/dist/js/adminlte.min.js?v=3.2.0'
 
import 'admin-lte/plugins/select2/css/select2.min.css'
import 'admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css'
import 'admin-lte/plugins/select2/js/select2.min.js'
// import 'admin-lte/plugins/pace-progress/pace.min.js'

// import './utils/pace';

import './utils/axios'
import { formatDate, formatTime } from './utils/moment';
Vue.prototype.$formatDate = formatDate;
Vue.prototype.$formatTime = formatTime;

// import moment from 'moment'
// Vue.prototype.$moment = moment;
 
// Global Components
import AppNavBar from './components/partials/AppNavBar'
import AppSideBar from './components/partials/AppSideBar'
import AppFooter from './components/partials/AppFooter'
import ControlSideBar from './components/partials/ControlSideBar'

Vue.component('AppNavBar', AppNavBar)
Vue.component('AppSideBar', AppSideBar)
Vue.component('AppFooter', AppFooter)
Vue.component('ControlSideBar', ControlSideBar)
 
import TopNavBar from './components/pos_partials/TopNavBar'
Vue.component('TopNavBar', TopNavBar)

import LoadingOverlay from './components/LoadingOverlay';
Vue.component('LoadingOverlay', LoadingOverlay);

import LoadingOverlayTwo from './components/LoadingOverlayTwo';
Vue.component('LoadingOverlayTwo', LoadingOverlayTwo);

import NoDataOverlay from './components/NoDataOverlay';
Vue.component('NoDataOverlay', NoDataOverlay);

import NoBillsOverlay from './components/NoBillsOverlay';
Vue.component('NoBillsOverlay', NoBillsOverlay);

import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);
 
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast, {
  timeout: 2000,
  position: 'top-right',
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  hideProgressBar: false,
  maxToasts: 1,
});

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2);

// Vue.use(VueSweetalert2, {
//   toast: true,
//   showConfirmButton: false,
//   timer: 2000,
//   timerProgressBar: false,
// });
  
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

import caslPlugin from './services/caslPlugin';
Vue.use(caslPlugin);

import Forbidden from './components/Forbidden';
Vue.component('Forbidden', Forbidden);

import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
Vue.use(VueTour);

import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)
 
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

// const checkAuthenticationLogic = () => {
//   return store.getters.isAuthenticated;
// };

// if (checkAuthenticationLogic()) {
//   Vue.use(caslPlugin);
// }

// const user_access = localStorage.getItem('permissions');

// defineAbilities(user_access);
 
// import Vuelidate from 'vuelidate';
// Vue.use(Vuelidate)

// import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";

// Vue.use(VuelidateErrorExtractor, {
//   i18n: false,
//   messages: {
//     required: '{attribute} field is required',
//     email: '{attribute} field must be a valid email'
//   }
// });

// const { extractErrors, validationMixin } = VuelidateErrorExtractor;
 
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
