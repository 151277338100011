import Vue from 'vue';
import router from '@/router';
import Cookies from 'js-cookie';

export function startSessionTimer(sessionTimeoutMinutes) {
  let sessionTimer;

  function resetTimer() {
    clearTimeout(sessionTimer);
    sessionTimer = setTimeout(logout, sessionTimeoutMinutes * 60 * 1000); // Convert minutes to milliseconds
  }

  function logout() {
    // Perform any logout actions, such as clearing authentication data or showing a logout message
    // Example: localStorage.removeItem('bearerToken');

    localStorage.removeItem('userID');
    localStorage.removeItem('roleID');
    localStorage.removeItem('username');
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('tokenExpirationTime');

    // Cookies.remove('jwt_token', { secure: true, sameSite: 'strict' });

    window.history.replaceState(null, '', '/login');
 
    router.push('/login'); // Redirect to the login page or any desired route
  }

  // Event listeners for user activity
  const events = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];
  events.forEach(event => {
    window.addEventListener(event, resetTimer);
  });

  // Start the session timer initially
  resetTimer();
}