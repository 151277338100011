<template>
  <div class="overlay-wrapper" v-if="loading">
    <div class="overlay">
      <i class="fas fa-3x fa-sync-alt fa-spin"></i>
      <div class="text-bold pt-2">Loading...</div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "LoadingOverlay",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
  
<style scoped>
</style>
  