import Vue from 'vue'
import Vuex from 'vuex'
// import permissions from './modules/permissions';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
  },
  mutations: {
    setAuthentication(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
  },
  actions: {
  },
  modules: {
    // permissions
  }
})
