<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link elevation-4">
      <img
        src="@/assets/kus-logo-dash.jpg"
        alt=""
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light"><b>KUS - Virtual Card</b></span>
    </a>

    <div class="sidebar">
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="@/assets/profiles/profile-02.png"
            class="img-circle elevation-3"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ username }}</a>
        </div>
      </div>

      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

          <li v-for="(menu, index) in menus" :key="index" :class="{'nav-item': true, 'active': isActiveMenu(index)}">
            <template v-if="menu.children && menu.children.length > 0">
              <a href="javascript:void(0)" class="nav-link" @click="toggleSubMenu(index)">
                <i :class="menu.icon_class" class="nav-icon"></i>
                <p> {{ menu.label }} <i class="right fas fa-angle-left"></i></p>
              </a>
              <ul class="nav nav-treeview" v-show="menu.showSubMenu">
                <li v-for="item in menu.children" :key="item.url" :class="{'nav-item': true, 'active': isActiveMenuItem(item.url)}">
                  <router-link :to="item.url" class="nav-link">
                    <i :class="item.icon_class" class="nav-icon"></i>
                    <p> {{ item.label }} </p>
                  </router-link>
                </li>
              </ul>
            </template>
            <template v-else>
              <router-link :to="menu.url" class="nav-link">
                <i :class="menu.icon_class" class="nav-icon"></i>
                <p> {{ menu.label }} </p>
              </router-link>
            </template>
          </li>

        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
export default {
  name: "AppSideBar",
  data() {
    return {
      username: localStorage.getItem('username'),
      activeMenuIndex: -1,
      menus: []
    };
  },
  mounted() {
    this.fetchMenus();
  },
  methods: {
    fetchMenus() {
      const token = localStorage.getItem('bearerToken');
      this.$axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
      this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/menus`)
      .then(response => {
        this.menus = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    },
    toggleSubMenu(index) {
      this.menus[index].showSubMenu = !this.menus[index].showSubMenu;
    },
    isActiveMenu(index) {
      return this.activeMenuIndex === index;
    },
    isActiveMenuItem(to) {
      return this.$route.path === to;
    }
  },
};
</script>

<style lang="scss"></style>