<template>
  <div v-if="!loading" class="noresult" style="display: none" :class="{ 'd-block': items.length == 0 }">
    <div class="text-center">
      <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions" :height="75" :width="75" />
      <template v-if="(getKeyword != '')" >
        <h5 class="mt-2">Sorry! No results for <b>"{{ getKeyword }}"</b></h5>
        <p class="text-muted mb-3">Try to find something else.</p>
      </template>
      <template v-else>
        <h5 class="mt-2">Sorry! No data found.</h5>
        <p class="text-muted mb-3">Please add data first.</p>
      </template>
    </div>
  </div>
</template>
  
<script>
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/tdrtiskw.json";

export default {
  name: "NoDataOverlay",
  components: {
    Lottie,
  },
  props: {
    loading: Boolean,
    items: Array,
    getKeyword: String,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
      },
    };
  },
};
</script>
  
<style scoped>
</style>
  