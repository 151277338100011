// import axios from 'axios';
// import { defineAbilities } from './ability';
// import store from '../store';

// function fetchAbilities() {
//   return new Promise((resolve, reject) => {
//     const token = localStorage.getItem('bearerToken');
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

//     axios.get(`${process.env.VUE_APP_BASE_URL}/api/abilities`)
//       .then(response => {
//         const permissionsData = response.data;
//         const ability = defineAbilities(permissionsData);
//         resolve(ability);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

// export default {
//   install(Vue) {
//     Vue.prototype.$ability = defineAbilities([]); // Set initial abilities to an empty array

//     if (store.getters.isAuthenticated) {
//       fetchAbilities()
//         .then(ability => {
//           Vue.prototype.$ability = ability;
//           console.log('Fetched abilities:', ability); // Log the abilities data (optional)
//         })
//         .catch(error => {
//           console.error('Failed to fetch user permissions', error);
//         });
//     }
//   },
// };

import axios from 'axios';
import { defineAbilities } from './ability';

export default {
  install(Vue) {

    Vue.prototype.$ability = defineAbilities([]);

    if(localStorage.getItem('bearerToken') != null)
    {
      const token = localStorage.getItem('bearerToken');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
      axios.get(`${process.env.VUE_APP_BASE_URL}/api/abilities`)
      .then(response => {
        const permissionsData = response.data;
        const ability = defineAbilities(permissionsData);

        Vue.prototype.$ability = ability;
      })
      .catch(error => {
        console.error('Failed to fetch user permissions', error);
      });
    }
  },
};